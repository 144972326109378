<template>
  <section>
    <section>
      <pm-Breadcrumb :home="home" :model="items_bread" />
      <div class="row">
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Presenças</h2>
        </div>
        <div class="col-md-2" >
           <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" style="float:right;margin-top: 25px;margin-bottom: 25px;"  @click="voltarParTurma()" />
        </div>
      </div>

      <div v-if="carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
          <pm-ProgressSpinner />
      </div>

      <div class="row">
        <div class="col-md-12"  v-if="!carregandoPagina && acesso.visualizar">
          <section>
            <div class="pm-card">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="titulo-links-escolha" style="margin-top: 0px !important;">
                    Alunos(as)
                  </h2>
                </div>
              </div>
              <div class="row" >
                <!--<div class="col-md-3" v-if="this.tipo_segmento!=1 && this.tipo_segmento!=0">-->
                <div class="col-md-3"  v-if="this.tipo_segmento == 2  || (this.tipo_segmento == 3 && selectSerie.tipo == 2) || selectSerie.possui_mais_de_um_professor">
                  <label>Selecione uma Disciplina:</label>
                  <select class="p-inputtext p-component"
                    v-model="disciplina_id" @change="buscarPresencasDisciplina()"
                    style="appearance: revert !important;width: 100% !important;"
                  >
                    <option   :value="n.id" v-for="n in disciplina" :key="n.id">{{n.nome}}</option>
                  </select>
                </div>

                <div class="col-md-12" style="margin-top:25px;">
                  <!--<selectCalendario2  v-if="this.tipo_segmento!=1 && this.tipo_segmento!=0 && disciplina_id!=0"
                    @exibirTabela="exibirTabela"
                    @test="montarCalendarioProfessor" :mes_nome="this.mes_exib_selec" :mes="this.mes_selec"
                    :tabelaDias="tabelaDias" :mesNumero="mes_numeral"
                    :diaSelecionado="this.info.dia" @dialogAction="dialogAction"
                  />-->

                  <selectCalendario2  v-if="((this.tipo_segmento!=1 ) || ( selectSerie.possui_mais_de_um_professor)) && disciplina_id != 0"
                    @exibirTabela="exibirTabela"
                    @test="montarCalendarioProfessor" :mes_nome="this.mes_exib_selec" :mes="this.mes_selec"
                    :tabelaDias="tabelaDias" :mesNumero="mes_numeral"
                    :diaSelecionado="this.info.dia" @dialogAction="dialogAction"
                  />
                  <selectCalendario2  v-if="this.tipo_segmento == 1 && !selectSerie.possui_mais_de_um_professor  && disciplina_id != 0"
                    @exibirTabela="exibirTabela"
                    @test="montarCalendarioProfessor" :mes_nome="this.mes_exib_selec" :mes="this.mes_selec"
                    :tabelaDias="tabelaDias" :mesNumero="mes_numeral"
                    :diaSelecionado="this.info.dia" @dialogAction="dialogAction"
                  />
                </div>

                <div class="col-md-12" style="margin-top:10px;"></div>
              </div>
              <div >
                <div v-if="abrirComponete && turma_id && (this.tipo_segmento == 1 || this.tipo_segmento == 0)">
                  <CalendarioVisualizar @test="montarCalendarioProfessor"
                    :index="maiorIndex"
                    :acesso="acesso" :presencasAlunosView="presencasAlunosView"
                    :tabelaDias="tabelaDias" :mes_nome="this.mes_exib_selec"
                    :mes_sel="this.mes_selec" :mes="this.mes_exib_selec"
                  />
                </div>

                <!--<div v-if="abrirComponete && turma_id && this.tipo_segmento!=1 && this.tipo_segmento!=0 && disciplina_id!=0">-->
                <div v-if="abrirComponete && turma_id && this.tipo_segmento!=1 && this.tipo_segmento!=0 && this.disciplina_id != 0">
                  <CalendarioVisualizar @test="montarCalendarioProfessor"
                    :index="maiorIndex"
                    :acesso="acesso" :presencasAlunosView="presencasAlunosView"
                    :tabelaDias="tabelaDias" :mes_nome="this.mes_exib_selec"
                    :mes_sel="this.mes_selec" :mes="this.mes_exib_selec"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <pm-Dialog header="Enviar presença" v-model:visible="displayMaximizable" :style="{width: '50vw'}" :maximizable="true" :modal="true">
        <div class="field col-12" v-if="this.info.dataSelecionada !=''">
          <div>
            <div class="row">
              <div v-if="info.dataSelecionada">
                <h2 class="text-01-presenca">
                  Data selecionada: {{info.dataSelecionada}} - envio de presença do dia para a disciplina de {{disciplina_nome}}
                </h2>
              </div>
            </div>
            <div class="p-fluid formgrid grid" >
                <div class="row">
                  <div class="row" v-if="selectable && info.dia" style="margin-top:5px;">
                    <h4 class="title-presenca-table">Alunos da Turma  </h4>
                    <table-prof-presenca01 @marcarPresenca="marcarPresenca" :alunos="alunos" :acesso="acesso" :st_segmento="this.segmento_id"
                        :etapaDeEnvioDePresenca="etapaDeEnvioDePresenca" />
                  </div>

                  <div class='row' v-if="this.selectable &&  info.dia" style="left:0px;width:133px;">
                    <va-inner-loading class=" py-3" style="width: 100%;" :loading="isLoading">
                      <button class="btn btn-primary" @click="presencaEnviar()" v-if="acesso.cadastrar && this.alunos.length">Enviar Presença</button>
                    </va-inner-loading>
                  </div>

                  <div v-if="editarOk && (this.tipo_segmento == 1 || this.serie_tipo == 1)">
                    <editarFundamental1 @marcarPresenca="marcarPresenca" :alunos="alunos" @EditarPresencaDaTurma="EditarPresencaDaTurma" @fecharEditarFundamentalUm="fecharEditarFundamentalUm"/>
                  </div>

                  <div v-if="editarOk && (this.tipo_segmento == 2 || this.serie_tipo == 2)">
                    <editarFundamental2 @marcarPresencaFundamentalDois="marcarPresencaFundamentalDois" :alunos="alunos" @EditarPresencaDaTurma="EditarPresencaDaTurma" @fecharEditarFundamentalUm="fecharEditarFundamentalUm"/>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <template #footer>
        </template>
      </pm-Dialog>

    </section>
  </section>
</template>


<script>
import axios from 'axios';
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Presenca } from "@/class/presenca";
import { DisciplinaEscolar } from "@/class/disciplina";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Verificar } from "@/class/verificar.js";
import { Servidores } from "@/class/servidores";
import { SeriesEscolar } from "@/class/serie.js";

import selectCalendario2 from '@/components/presenca/selectCalendario2.vue'
import CalendarioVisualizar from '@/components/presenca/calendarioVisualizar.vue'

import tableProfPresenca01 from '@/components/presenca/tableProfPresenca01.vue'
import editarFundamental1 from '@/components/presenca/editarFundamental1.vue'
import editarFundamental2 from '@/components/presenca/editarFundamental2.vue'
import dataMatriculaAlunos from "@/utils/dataMatriculaAlunos.json";

export default defineComponent({
  props: {
    escola_id:{
      required: true
    },
    segmento_id:{
      required: true
    },
    periodo_id:{
      required: true
    },
    turma_id:{
      required: true
    },
    pg:{}
  },
  components: {
    selectCalendario2,
    CalendarioVisualizar,
    tableProfPresenca01,
    editarFundamental1,
    editarFundamental2
  },
  data () {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "ano", label: "Ano", sortable: true },
      { key: 'aluno', label:'Ação'},
    ];
    return {
      base_url: axios.defaults.baseURL.replace('/api/v1',''),


      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Presenças'},
      ],
      filter:'',
      columns,

      abrirComponete:false,
      maiorIndex:0,
      tipo_segmento:0,
      serie_tipo: 0,
      tabelaDias:[],
      tabelaDiasApenasAnosLetivoColunas:[],
      info:[],
      dataSelecionaFormatoEUA:'',
      presencasAlunosView:[],
      mes_selec:'',
      mes_exib_selec:'',
      todosOsAlunos: [],
      displayMaximizable:false,
      selectable:true,
      isLoading:false,
      mesLoading:false,
      porcentagemParaSerAprovado:70,

      aluno:0,
      alunos: [],
      presenca:0,
      theturma:null,
      alunosPresente: [],
      presenca:0,
      professor_id:null,
      segmentoInfo:null,
      serieInfo:null,
      professorInfo: '',
      mes:0,
      disciplina: [],
      presencas: [],
      disciplina_id: 0,
      disciplina_nome: null,

      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      },
      meses : [
        {numero:'01', palavra: 'janeiro'},
        {numero:'02', palavra: 'fevereiro'},
        {numero:'03', palavra: 'marco'},
        {numero:'04', palavra: 'abril'},
        {numero:'05', palavra: 'maio'},
        {numero:'06', palavra: 'junho'},
        {numero:'07', palavra: 'julho'},
        {numero:'08', palavra: 'agosto'},
        {numero:'09', palavra: 'setembro'},
        {numero:'10', palavra: 'outubro'},
        {numero:'11', palavra: 'novembro'},
        {numero:'12', palavra: 'dezembro'},
      ],
      tabelaPresencasColunas:[],
      carregandoPagina:false,

    }
  },
  methods:{
    //Receber todos os dia que possuem presencas
    receberTodosOsDiasQueExistemPresencas() {
      let myPresencasDias = new Set();
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        for (var j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (!myPresencasDias.has(this.presencasAlunosView[i].presencas[j].dia)) {
            myPresencasDias.add(this.presencasAlunosView[i].presencas[j].dia);
          }
        }
      }
      return Array.from(myPresencasDias).sort((a, b) => a - b);
    },
    //Buscar todas as presencas que existem em um dia X
    todasAsPresencasQueExistemNoDia(dia) {
      let mySet = new Set();
      let todasAsPresencasNoDia = [];
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        for (var j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (!mySet.has(this.presencasAlunosView[i].presencas[j].data) && dia == this.presencasAlunosView[i].presencas[j].dia) {
            todasAsPresencasNoDia.push(this.presencasAlunosView[i].presencas[j]);
            mySet.add(this.presencasAlunosView[i].presencas[j].data);
            //Para add dias para criar as colunas da tabela
            this.tabelaPresencasColunas.push(dia);
          }
        }
      }
      //console.log(todasAsPresencasNoDia);
      return todasAsPresencasNoDia.sort(function (a, b) {
	        return a.presenca_id - b.presenca_id;
      });
    },
    criarTabelaDePresencasParaCadaAluno(mapOfValues) {
        //Para exibir os dados na tabela de presenca
        for (var i = 0; i < this.presencasAlunosView.length; i++) {
            this.presencasAlunosView[i].presencas1 = [];
        }
        for (const dia of mapOfValues) {
          var presencasDoAluno = this.todasAsPresencasQueExistemNoDia(dia);
          for (var i = 0; i < this.presencasAlunosView.length; i++) {
            for (var j = 0; j < presencasDoAluno.length; j++) {
              //Verificar se o aluno i possue a preseja j
              const found = this.presencasAlunosView[i].presencas.find(element => element.data == presencasDoAluno[j].data);

              if (found != undefined) {
                this.presencasAlunosView[i].presencas1.push(found);
              }
              else {
                this.presencasAlunosView[i].presencas1.push(' ');
              }
            }

          }
        }
    },
    voltarParTurma(){
      this.$router.push({ name: "coordenacao-turma-hab",  params: { pg: this.pg }});
    },
    fecharEditarFundamentalUm() {
      this.editarOk = !this.editarOk;
    },
    async listarEscolas(){
      let esc = await Escola.obtemTodos();

      for (const el of esc.data) {
        let novo = {
          id_: el.id,
          id: el.id,
          logradouro:el.logradouro,
          bairro: el.bairro,
          cep: el.cep,
          cidade: el.cidade,
          cnpj: el.cnpj,
          email: el.email,
          estado: el.estado,
          nome: el.nome,
          numero: el.numero,
          items:[
            {
              label: 'Editar Escola',
              icon: 'pi pi-pencil',
              command: () => {
                 this.editarEscola(el.id)
              }
            },
            {
              label: 'Informações da Escola',
              icon: 'pi pi-search-plus',
              command: () => {
                this.editarInfo(el.id)
              }
            },

          ]
        };
      this.escolas.push(novo);
      };

    },
    async presenca_tudo_turma(id) {
      let data = await Turma.obtemPresencaTudo(id);
      this.alunos = data.data;
    },
    async listarTudo(){
      try{
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);
        const calendario = await Calendario.obtemTodos();

        if(whoiam.data.funcao != 5){
          this.$router.push({ name: "login"});
        }

      }catch(e){}
    },

    async buscarPresencasDisciplina(){
      const date = new Date();
      const today = date.getDate();
      const currentMonth = date.getMonth() + 1;
      if(currentMonth == 2){await this.montarCalendarioProfessor('fevereiro', 'Fevereiro');}
      if(currentMonth == 3){await this.montarCalendarioProfessor('marco', 'Março');}
      if(currentMonth == 4){await this.montarCalendarioProfessor('abril', 'Abril');}
      if(currentMonth == 5){await this.montarCalendarioProfessor('maio', 'Maio');}
      if(currentMonth == 6){await this.montarCalendarioProfessor('junho', 'Junho');}
      if(currentMonth == 7){await this.montarCalendarioProfessor('julho', 'Julho');}
      if(currentMonth == 8){await this.montarCalendarioProfessor('agosto', 'Agosto');}
      if(currentMonth == 9){await this.montarCalendarioProfessor('setembro', 'Setembro');}
      if(currentMonth == 10){await this.montarCalendarioProfessor('outubro', 'Outubro');}
      if(currentMonth == 11){await this.montarCalendarioProfessor('novembro', 'Novembro');}
      if(currentMonth == 12){await this.montarCalendarioProfessor('dezembro', 'Dezembro');}
    },

    async montarCalendarioProfessor(mes, mesExibir){
      this.carregandoPagina = true;
      this.turmaAlunos();
      this.mes_selec = mes;
      this.mes_exib_selec = mesExibir;
      this.mesLoading = true;

      this.tabelaDias = [];
      this.info.dia = '';
      this.info.semana = '';
      this.info.dataSelecionada = '';
      let anoSelect = sessionStorage.getItem("anoSelecionado");
      this.info.ano = anoSelect;
      if(this.tipo_segmento == 1){
        this.disciplina_id = 1;
        this.disciplina_nome = "Todas as Disciplinas";
      }else{
        let busca_disciplina = this.disciplina.find(element => element.id == this.disciplina_id);
        if(busca_disciplina){
          this.disciplina_nome = busca_disciplina.nome;
        }
      }

      //receber mês número
      const found = this.meses.find(element => element.palavra == mes);
      this.mes_numeral = found.numero;

      let novo = {
        disciplina_id: this.disciplina_id,
        mes: mes,
        turma_id: this.turma_id,
        segmento_id: this.segmento_id,
        serie: this.periodo_id,
        ano: anoSelect,
      }

      let data = await Presenca.presencaMontarCalendarioProfessor(novo);
      this.tabelaDias = data.data;
      this.tabelaDiasApenasAnosLetivoColunas = [];
        //Apenas com dia letivo
      for (var i = 0; i < this.tabelaDias.length; i++) {
        if (this.tabelaDias[i].letivo == '0')
            continue;
        if (this.tabelaDias[i].dia.length == 1)
            this.tabelaDias[i].dia = '0' + this.tabelaDias[i].dia;
            this.tabelaDiasApenasAnosLetivoColunas.push(this.tabelaDias[i]);
      }

      this.mesLoading = false;

      //presencas visu
      let nv1 = {
        disciplina_id: this.disciplina_id,
        mes: mes,
        turma_id: this.turma_id,
        ano: anoSelect,
      };
      let data1 = await Presenca.mesesVisu(nv1);
      this.presencasAlunosView = [];

      if(data1.data.length>0){
        for (const el of data1.data) {
            let info = {
                nome : el.aluno.nome + ' ' + el.aluno.sobrenome,
                evento_saida: el.evento_saida,
                presencas : [],
                cntPresencas:0,
                data_entrada_turma: el.data_entrada_na_turma,
                data_entrada_turma_br: el.data_entrada_na_turma_br,
                entrou_no_prazo_certo:false,
            };
            //verificar se ele entrou no prazo certo na turma
           if (el.data_entrada_na_turma >= dataMatriculaAlunos[0].data_inicial_matricula && el.data_entrada_na_turma <= dataMatriculaAlunos[0].data_final_matricula) {
              info.entrou_no_prazo_certo = true;
           }

            let cntPresenca = 0;
            for (const el1 of el.presenca) {
                let novo = {
                  presenca_id: el1.id,
                  aluno_id: el.aluno.id,
                  data: el1.data,
                  disciplina_id: el1.disciplina_id,
                  escola_id: el1.escola_id,
                  id_presenca: el1.id,
                  justificacao_conteudo: el1.justificacao_conteudo,
                  justificada_falta: el1.justificada_falta,
                  presenca: el1.presenca,
                  professor_id: el1.professor_id,
                  segmento_id: el1.segmento_id,
                  serie_id: el1.serie_id,
                  turma_id: el1.turma_id,
                  dia:''
                }
                //contar presencas
                info.cntPresencas += el1.presenca;
                //receber dia
                let receberDia = novo.data.split('-');
                receberDia = receberDia[2].split(" ");
                novo.dia = receberDia[0];
                info.presencas.push(novo);
            }
          this.presencasAlunosView.push(info);
          //professor
          this.professor_id = 5;
        }


        this.tabelaPresencasColunas = [];
        this.criarTabelaDePresencasParaCadaAluno(this.receberTodosOsDiasQueExistemPresencas());
        this.presencasAlunosView[0].tableColuna =  this.tabelaPresencasColunas;
        console.log("dadadas");
        this.carregandoPagina = false;
        this.abrirComponete = true;
        //abrir modal
        return true;
      }
    },

    dialogAction() {
      this.alunos = [];

      for (const el of this.todosOsAlunos) {
        let dataDeEntradaNaTurma = el.created_at.split('T');
        dataDeEntradaNaTurma = dataDeEntradaNaTurma[0];
         //Verificar se a data que o aluno entrou na turma é menor ou igual a data selecionada
        if (dataDeEntradaNaTurma <= this.dataSelecionaFormatoEUA) {
          this.alunos.push(el);
        }
      }
      if (!this.alunos.length) {
        this.$vaToast.init({
          message: "A turma não tinha alunos na data selecionada!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'warning',
          duration: 3000,
          fullWidth: false,
        });
      }
      else {
        this.displayMaximizable = true;
      }
    },

    async exibirTabela(dia, semana, mes, mesLetra) {
      this.editarOk = false;
      this.info.dia = dia;
      this.info.mes = mesLetra;
      this.info.semana = semana;
      if (dia.length == 1)
        dia = '0' + dia;
      this.info.dataSelecionada = dia+'/'+mes+'/'+this.info.ano;
      this.dataSelecionaFormatoEUA = this.info.ano+'-'+mes+'-'+dia;
    },

    async turmaAlunos() {
      this.todosOsAlunos = [];
      let data = await Turma.obtemAlunosTurma(this.turma_id);
      this.todosOsAlunos = data.data;
    },

    marcarPresenca(n,item){
      item.presente = n;
    },

    marcarPresencaFundamentalDois(chamada, n, item){
      if (chamada == 1)
        item.presenca_1 = n;
      else
        item.presenca_2 = n;
    },


    async presencaEnviar(){
      try {
        this.alunosPresente = [];

        //array dos alunos
        for (var i = 0; i < this.alunos.length; i++) {
          if (this.alunos[i].presente == undefined) {
            alert("Adicione as informações de presença para todos os alunos!");
            return false;
          }
          let novo = {
            aluno_id: this.alunos[i].id,
            presenca:  this.alunos[i].presente,
            justificada_falta: 0,
            justificacao_conteudo:''
          }
          this.alunosPresente.push(novo);
        }
        console.log(this.alunos);
        //2022-05-26 10:38:11
        //Receber a hora atual
        let now = new Date;
        let horas = String(now.getHours());
        let minutos = String(now.getMinutes());
        let segundos = String(now.getSeconds());
        if (horas.length == 1)
          horas = "0" + horas;
        if (minutos.length == 1)
          minutos = "0" + minutos;
        if (segundos.length == 1)
          segundos = "0" + segundos;

        let horaAtual = horas + ':'  + minutos + ':' + segundos;

        //Data selecionada pelo usuário
        let dataFormatoUSA = this.info.dataSelecionada.split("/");
        let dataAtual =  dataFormatoUSA[2] + '-' + dataFormatoUSA[1] + '-' + dataFormatoUSA[0];
        let dataEHora = dataAtual + ' ' + horaAtual;

        //informacoes importantes
        let info = {
          segmento_id: this.segmento_id,
          escola_id: this.escola_id,
          turma_id: this.turma_id,
          serie_id: this.periodo_id,
          professor_id: this.professor_id,
          disciplina_id:  this.disciplina_id,
          data: dataEHora,
        }
        console.log("dsda", this.alunosPresente);
        let data = await Presenca.criarPresencaDosAlunos(this.alunosPresente, info);
        //fechar modal
        this.displayMaximizable = false;
        this.$vaToast.init({
          message: "Presença enviada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });

        this.turmaAlunos();

        this.montarCalendarioProfessor(this.mes_selec,this.mes_exib_selec);
        this.isLoading = false;

      }catch(e) {
       // console.log(e);
         this.$vaToast.init({
          message: "Error ao enviar presença!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
        this.isLoading = false;
      }

    },

    async EditarPresencaDaTurma(alunos) {
      try {
        this.alunosPresente = [];
        for (var i = 0; i < alunos.length; i++) {
          if (alunos[i].presente != undefined) {
            let novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome:  alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presente,
              presenca_2: null,
              ano: this.info.ano,
              mes: this.info.mes,
              dia: this.info.dia
            }
            this.alunosPresente.push(novo);
          }
          else {
            let novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome:  alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presenca_1,
              presenca_2: alunos[i].presenca_2,
              ano: this.info.ano,
              mes: this.info.mes,
              dia: this.info.dia
            }
            this.alunosPresente.push(novo);
          }
        }

        //Gerar presencas

        let pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
        let data = await Presenca.EditarPresencaDaTurma(this.alunosPresente, pf.data.id,
        this.disciplinaSelecionadaPeloProf.turma_disciplina_id, this.infoParaEnviar.turma_id);

        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });
        this.editarOk = false;
      }
      catch(e) {
        this.$vaToast.init({
          message: "Problema ao editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
      }
    },


    /*async buscarPresencasDisciplina(){
      if (this.mes != undefined && this.mes != '' &&  this.disciplina_id != undefined && this.disciplina_id != '') {
        let anoSelect = sessionStorage.getItem("anoSelecionado");
        let payload = {
          mes: this.mes,
          turma_id: this.turma_id,
          disciplina_id: this.disciplina_id.id,
          ano: anoSelect,
        };
        this.presencas = [];
        let data = await Presenca.mesesVisu(payload);
        for (const el of data.data) {
          for (const el1 of el.presenca) {
            let dataFormatoUS = el1.data.split(' ');
            let dataFormatoBR = dataFormatoUS[0];
            dataFormatoBR = dataFormatoBR.split('-');
            dataFormatoBR = dataFormatoBR[2]+'/'+dataFormatoBR[1]+'/'+dataFormatoBR[0] + ", " + dataFormatoUS[1];
            el1.dataBR = dataFormatoBR;
          }
        }
        this.presencas = data.data;
      }
      if (this.presencas[0].presenca[0].professor_id != undefined) {
        let data1 = await Servidores.obtemUm(this.presencas[0].presenca[0].professor_id);
        this.professorInfo = data1.data[0];
      }
    },*/
    async buscarSegmentoALL() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = [];
      for (const el of data.data) {
        if (el.id == 5)
          continue;
        this.segmentoALL.push(el);
      }
    },

    async listaSerie (id) {
      let data = await SegmentoEscolar.listarSegmentosPorSerie(id);

      this.serieALL = data.data;
      return this.seriesALL;
    },

    async listaTurmas(){
      let play = {
        'escola':this.escola_id,
        'serie': this.periodo_id,
        'segmento': this.segmento_id,
      };

      let data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;
    },

    async listarAlunosdaTurma(){
      let data = await Turma.alunoEmUmaTurma(this.turma);
      this.alunoALL = data.data;
    },

    async listaDisciplina(){
      let data = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma_id);
      this.disciplina = [];
      for (const el of data.data) {
        if(el.na_turma == 1){
          let novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplina.push(novo);
        }
      }
    }
  },
  async beforeMount() {
    if (this.turma_id == undefined) {
      this.$router.push({ name: "coordenacao-turma-hab"});
    }
    let acesso = await Verificar.AcessoLocal(10);
    await this.listaDisciplina();
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;


    let dataseg = await SegmentoEscolar.obtemUm(this.segmento_id);
    this.tipo_segmento = dataseg.data.tipo;

    let dat = await SeriesEscolar.obtemUm(this.periodo_id);
    this.selectSerie = dat.data;
    if(this.tipo_segmento != 2){
      this.serie_tipo = dat.data.tipo;
    }else{
      this.serie_tipo = 0;
    }

    this.buscarPresencasDisciplina();
   // dataMatriculaAlunos.forEach(x => { console.log(x); })
  }
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>

<style>
 .th_prf{
    background-color: #0067a7 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
 }
 .th_prf1{
    background-color:  #2e92d1 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
 }
 .tbody_prf{
    border-top: 0px solid currentColor !important;
 }
</style>
